<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
</head>

<template>
  <div id="about">
    <div id="main-grid">
      <div id="header-nav">
        <div id="normal-logo" @click="redirect('')"><img id="logo" src="../assets/images/stilsons-lighting2.svg" alt="logo"></div>
        <div id="menu" v-if="desktop == true">
              <a href="" @click="redirect('')">Home</a>
              <a href="" @click="redirect('about')">Our Company</a>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Residential Lighting</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('accent')">Accent Lighting</a>
                      <a href="" @click="redirect('holiday')">Holiday Lighting</a>
                      <a href="" @click="redirect('security')">Security Lighting</a>
                      <a href="" @click="redirect('gameday')">Game Day Lighting</a>
                  </div>
              </li>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Resources</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('consult')">Contact Us</a>
                      <a href="" @click="redirect('faq')">FAQ</a>
                  </div>
              </li>
              <button id="nav-button" @click="redirect('consult')">Request Quote</button>
            </div>
        <div v-if="desktop == false">
          <v-icon id="ham-icon"  @click="drawer = true" size="30px">mdi-menu</v-icon>

          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            right
          >
            <v-list
              nav
              dense
            >
              <v-list-item-group
                v-model="group"
                active-class="deep-purple--text text--accent-4"
              >
                <v-list-item>
                  <v-list-item-title @click="redirect('')">Home</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title @click="redirect('about')">Our Company</v-list-item-title>
                </v-list-item>

                <v-list-item @click="residential = !residential">
                  <v-list-item-title>Residential Lighting</v-list-item-title>
                </v-list-item>

                
                <v-list-item v-if="residential == true">
                  <v-list-item-title class="subNav" @click="redirect('accent')">Accent Lighting</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="residential == true">
                  <v-list-item-title class="subNav" @click="redirect('holiday')">Holiday Lighting</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="residential == true">
                  <v-list-item-title class="subNav" @click="redirect('security')">Security Lighting</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="residential == true">
                  <v-list-item-title class="subNav" @click="redirect('gameday')">Game Day Lighting</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="residential == true">
                  <v-list-item-title class="subNav">Commercial</v-list-item-title>
                </v-list-item>
                


                <v-list-item @click="resources = !resources">
                  <v-list-item-title>Resources</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="resources == true">
                  <v-list-item-title class="subNav" @click="redirect('faq')">FAQ Page</v-list-item-title>
                </v-list-item>
                
                <v-list-item v-if="resources == true">
                  <v-list-item-title class="subNav" @click="redirect('consult')">Contact Us</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title @click="redirect('consult')">Free Consultation</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
            
        </div>
      </div>
    </div>


    <div id="body-grid">
        <div id="color-head">
          <h1 id="hero">Our Company</h1>
        </div>
        <div id="header-img-flex"><img src="../assets/images/rainbow.jpeg" alt="" class="header-img"></div>
        <!-- <img src="../assets/images/rainbow.jpeg" alt="" id="header-img"> -->
        <div id="back-ground">
          <h2 id="top-sub">Stilson's Central Lighting</h2>
          <p style="display:block">Stilson’s have been living and working in the construction industry in the Saint George area for 37 years. Blaine and Jason Stilson have always been passionate about the field. They have always loved holiday lighting and have installed multiple brands of lighting for the past 15 years. 4 years ago, they settled on Jellyfish Lighting because of their superior features, quality, and ease of use.</p>

          <img src="../assets/images/jellyfish_logo_white.png" alt="" style="margin: 100px">

          <h2 id="subs">Jellyfish Authorized Dealer</h2>
          <p style="display: block; margin-bottom: 50px">JellyFish lighting is a permanent color changing LED lighting system for the outside of the home.  It is can be used as accent lighting or change to any color pattern for your holidays and parties. Create your own patterns or choose from the 102 pre programed colors. JellyFish is easily controlled in an app from any smart device.
</p>

        </div>

    </div>

    <div id="footer">
      <div>
        <h1 id="reqHead">Request A Free Quote Today!</h1>
      </div>
      <div id="footer-quote">
          <input type="text" v-model="name" value="" placeholder="Name" class="quote-item">
          <input type="text" v-model="email" value="" placeholder="Email" class="quote-item">
          <input type="text" v-model="phone" value="" placeholder="Phone" class="quote-item">
          <button class="quote-button" @click="sendMail()">Request Quote</button>
      </div>

      <div id="footer-content">
          <img src="../assets/images/stilsons-lighting2.svg" alt="" class="footer-logo">
          <h6 class="footer-text">St. George Area: 1205 East 1050 South Circle, 84790</h6>
          <h6 class="footer-text"><a href="https://www.stilsons.com/">Our Partner Company: Stilson’s Central Vacuum</a></h6>
      </div>

    </div>





  </div>

</template>

<script>
export default {
  name: 'About',
  data(){
    return {
      desktop: true,
      drawer: false,
      group: null,
      residential: false,
      resources: false,
      name: '',
      email: '',
      phone: ''
      
    }
  },
  created(){
      window.scrollTo(0, 0);
      const width1 = window.innerWidth;
      console.log("width: ", width1);
      if(width1 <= 768){
        this.desktop = false;
      }
  },
  methods: {
    redirect(x){
      this.$router.push(`/${x}`);
    },
    sendMail(){
      window.open(`mailto:stilsons@stilsons.com?subject=${this.name} requested a quote&body=${this.name} requested a quote. %0D%0A Here is their email: ${this.email} %0D%0A Phone number: ${this.phone}. %0D%0A %0D%0A Any additional comments:  %0D%0A %0D%0A Sent from Stilson's Lighting Website`);
    }

  },
  computed: {
    size(){
      const width1 = window.innerWidth;
      console.log("width: ", width1);
      if(width <= 768){
        this.desktop = false;
      }
    }
    
  }
}
</script>

<style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #37445B;
    margin-left: 20px;
}

.dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding-left: 42px;
    font-size: 18px;
}
.dropdown-content a {
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    /* margin-left: 22px; */
    font-size: 18px;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    width: 200px;
}

.dropdown-content a:hover {
    background-color: #f1f1f157;
}
#reqHead{
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
#normal-logo{
  margin-top: 0px;
}
#main-grid{
  background-image: url(../assets/images/grad.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#body-grid{
  width: 100%;
  margin: 0px;
  display: grid;
  grid-template-columns: minmax(0px,100px) repeat(6, minmax(0,575px)) minmax(0px,100px);
}
#color-head{
  grid-column: -1/1;
  grid-row: 1/3;
  height: 400px;
  background: linear-gradient(180deg, #67B3E4 0%, #C292DE 100%);
}
#header-img-flex{
  grid-column: 1/-1;
  grid-row: 2/4;
  height: auto;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.header-img{
  width: 32%;
}
#hero{
  width: unset; 
  text-align:center;
  padding-top: 32px;
}
#back-ground{
  background-image: url(../assets/images/grad.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  grid-column: -1/1;
  grid-row: 3/4;
  
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
#top-sub{
  margin-top: 15%;
  font-size: 72px;
  font-weight: bold;
}
p{
  width: 75%;
  margin-top: 100px;
  text-align: center;
}
#subs{
  font-size: 72px;
  font-weight: bold;
}
#hero{
  padding-top: 2% !important;
}
.v-navigation-drawer--close{
  display: none;
}
@media (max-width: 1440px){
  .header-img{
    width: 50%;
  }
  #top-sub{
    margin-top: 23%;
  }
}
@media (max-width: 1024px){
  #top-sub{
    margin-top: 200px;
  }
  #reqHead{
    font-size: 60px;
  }
  .header-img{
    width: 56%;
  }
  .dropbtn{
    font-size: 12px;
  }
  .dropdown-content a{
    font-size: 12px;
  }

}
@media (max-width: 768px){
  #reqHead{
    font-size: 50px;
  }
  .quote-item{
    width: 80%;
  }
  .quote-button{
    width: 80%;
  }
  #top-sub{
    margin-top: 163px;
    font-size: 48px;
  }
  #subs{
    font-size: 48px !important;
  }
  #color-head{
    height: 238px;
  }
  p{
    margin-top: 50px !important;
  }
  .quote-item{
    width: 75vw;
  }
  .quote-button{
    width: 75vw;
  }
}

@media (max-width: 425px){
  #footer-quote{
    padding: 50px 50px 0px 50px;
  }
  #header-img{
    grid-column: 2/8 !important;
  }
  #hero{
    padding-top: 8% !important;
    font-size: 40px !important;
  }
  #top-sub{
    font-size: 28px;
    margin-top: 120px;
  }
  #subs{
    font-size: 28px !important;
  }
  p{
    font-size: 14px !important;
    margin-top: 30px !important;
    width: 80% !important;
  }
  .header-img{
    width: 84%;
  }
}
@media (max-width: 320px){
  #color-head{
    height: 180px;
  }
  #reqHead{
    font-size: 36px;
  }
}
</style>