<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
</head>

<template>
  <div id="main-grid">

    <div class="header-grid">
        <div id="header-nav">
            <div id="normal-logo"><img id="logo" src="../assets/images/stilsons-lighting2.svg" alt="logo"></div>
            <div id="menu" v-if="desktop == true">
              <a href="" @click="redirect('')">Home</a>
              <a href="" @click="redirect('about')">Our Company</a>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Residential Lighting</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('accent')">Accent Lighting</a>
                      <a href="" @click="redirect('holiday')">Holiday Lighting</a>
                      <a href="" @click="redirect('security')">Security Lighting</a>
                      <a href="" @click="redirect('gameday')">Game Day Lighting</a>
                  </div>
              </li>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Resources</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('consult')">Contact Us</a>
                      <a href="" @click="redirect('faq')">FAQ</a>
                  </div>
              </li>
              <button id="nav-button" @click="redirect('consult')">Request Quote</button>
            </div>
            <div v-if="desktop == false">
              <v-icon id="ham-icon"  @click="drawer = true" size="30px">mdi-menu</v-icon>

              <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
                right
              >
                <v-list
                  nav
                  dense
                >
                  <v-list-item-group
                    v-model="group"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <v-list-item>
                      <v-list-item-title @click="redirect('')">Home</v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title @click="redirect('about')">Our Company</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="residential = !residential">
                      <v-list-item-title>Residential Lighting</v-list-item-title>
                    </v-list-item>

                    
                    <v-list-item v-if="residential == true">
                      <v-list-item-title class="subNav" @click="redirect('accent')">Accent Lighting</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="residential == true">
                      <v-list-item-title class="subNav" @click="redirect('holiday')">Holiday Lighting</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="residential == true">
                      <v-list-item-title class="subNav" @click="redirect('security')">Security Lighting</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="residential == true">
                      <v-list-item-title class="subNav" @click="redirect('gameday')">Game Day Lighting</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="residential == true">
                      <v-list-item-title class="subNav">Commercial</v-list-item-title>
                    </v-list-item>
                    


                    <v-list-item @click="resources = !resources">
                      <v-list-item-title>Resources</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="resources == true">
                      <v-list-item-title class="subNav" @click="redirect('faq')">FAQ Page</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item v-if="resources == true">
                      <v-list-item-title class="subNav" @click="redirect('consult')">Contact Us</v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title @click="redirect('consult')">Free Consultation</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
                
                
            </div>

        </div>
        
        <div id="hero-text">
            <h1 class="grad-text">Discover</h1>
            <h1>Southern Utah's Lighting Experts</h1>
        </div>
        <div id="hero-subtext">
            <img id="jellyfish" src="../assets/images/jellyfish_logo_white.png" alt="jellylogo">
            <h2 id="sub">Jellyfish Lighting Authorized Dealer</h2>
        </div>
        <button id="hero-button" @click="redirect('consult')">Free Quote &#8594</button>

    </div>

    <div class="applict">
        <h3>All-In-One Lighting Application</h3>
        <div id="app-boxs">
            <div @click="redirect('accent')">Accent Lighting</div>
            <div @click="redirect('holiday')">Holiday Lighting</div>
            <div @click="redirect('gameday')">Game Day Lighting</div>
            <div @click="redirect('security')">Security Lighting</div>

        </div>

    </div>
    <div id="syst">
        <h1>System Features</h1>
    </div>

    <div class="accord-list">
        <div id="rain1"> 
            <h5 class="rotate">Custom Accent Lighting</h5>
            <p>Create your own accent lighting to best showcase your home, choosing which lights you want on and what color from over 16 million different colors and any hue of white</p>
            <!-- <button class="rain-but1">Learn More</button> -->
        </div>
        <div id="rain2">
            <h5 class="rotate">Create Custom Patterns</h5>
            <p>Choose from over 16 million different colors to create custom patterns with over 20 different movements and effects, you can also choose from 102 preset patterns from our customer’s recommendations</p>
            <!-- <button class="rain-but2">Learn More</button> -->
        </div>
        <div id="rain3">
            <h5 class="rotate">Zoning</h5>
            <p>Ability to only turn on certain sections of lights or to have different patterns on different parts of your home at the same time (front versus back)</p>
            <!-- <button class="rain-but3">Learn More</button> -->
        </div>
        <div id="rain4">
            <h5 class="rotate">Proprietary Technology</h5>
            <p>We own and manufacture our patented lighting system and mobile app to ensure the highest quality and availability, we also release continual updates with new features based on customer requests</p>
            <!-- <button class="rain-but4">Learn More</button> -->
        </div>
        <div id="rain5">
            <h5 class="rotate">Multiple Timers & Events</h5>
            <p>Save multiple nightly & weekly timers as well as calendar events for holidays and other special occasions</p>
            <!-- <button class="rain-but5">Learn More</button> -->
        </div>
        <div id="rain6">
            <h5 class="rotate">Long Lasting</h5>
            <p>The JellyFish Lighting System is a robust, outdoor rated LED lighting system. LED lighting is known for its durability. The LEDs are designed for years and years of use.</p>
            <!-- <button class="rain-but6">Learn More</button> -->
        </div>
        <div id="rain7">
            <h5 class="rotate">Discreet from the Street</h5>
            <p>Mounted under your eaves in a custom track color with no exterior power supplies</p>
            <!-- <button class="rain-but7">Learn More</button> -->
        </div>
        <div id="rain8">
            <h5 class="rotate">Bright Energy & Efficent</h5>
            <p>Bright 3 diode lights use less than one watt per light at the brightest white and are dimable</p>
            <!-- <button class="rain-but8">Learn More</button> -->
        </div>

    </div>

    <div class="accord-list2">
        <div id="rain1m"> 
            <h5 class="">Custom Accent Lighting</h5>
            <p>Create your own accent lighting to best showcase your home, choosing which lights you want on and what color from over 16 million different colors and any hue of white</p>
            <!-- <button class="rain-but1">Learn More</button> -->
        </div>
        <div id="rain2m">
            <h5 class="">Create Custom Patterns</h5>
            <p>Choose from over 16 million different colors to create custom patterns with over 20 different movements and effects, you can also choose from 102 preset patterns from our customer’s recommendations</p>
            <!-- <button class="rain-but2">Learn More</button> -->
        </div>
        <div id="rain3m">
            <h5 class="">Zoning</h5>
            <p>Ability to only turn on certain sections of lights or to have different patterns on different parts of your home at the same time (front versus back)</p>
            <!-- <button class="rain-but3">Learn More</button> -->
        </div>
        <div id="rain4m">
            <h5 class="">Proprietary Technology</h5>
            <p>We own and manufacture our patented lighting system and mobile app to ensure the highest quality and availability, we also release continual updates with new features based on customer requests</p>
            <!-- <button class="rain-but4">Learn More</button> -->
        </div>
        <div id="rain5m">
            <h5 class="">Multiple Timers & Events</h5>
            <p>Save multiple nightly & weekly timers as well as calendar events for holidays and other special occasions</p>
            <!-- <button class="rain-but5">Learn More</button> -->
        </div>
        <div id="rain6m">
            <h5 class="">Long Lasting</h5>
            <p>The JellyFish Lighting System is a robust, outdoor rated LED lighting system. LED lighting is known for its durability. The LEDs are designed for years and years of use.</p>
            <!-- <button class="rain-but6">Learn More</button> -->
        </div>
        <div id="rain7m">
            <h5 class="">Discreet from the Street</h5>
            <p>Mounted under your eaves in a custom track color with no exterior power supplies</p>
            <!-- <button class="rain-but7">Learn More</button> -->
        </div>
        <div id="rain8m">
            <h5 class="">Bright Energy & Efficent</h5>
            <p>Bright 3 diode lights use less than one watt per light at the brightest white and are dimable</p>
            <!-- <button class="rain-but8">Learn More</button> -->
        </div>

        <h1 id="gall-header2">Gallery</h1>
        <div id="gall-grad2">


              
              <v-carousel hide-delimiters height="300px">
                <v-carousel-item height="300px"
                  src="../assets/images/rainbow.jpeg"
                ></v-carousel-item>
                <v-carousel-item height="300px"
                  src="../assets/images/white.jpeg"
                ></v-carousel-item>
                <v-carousel-item height="300px"
                  src="../assets/images/thanksgiving.jpeg"
                ></v-carousel-item>
              </v-carousel>
              


        </div>
        <div id="about-part2">
            <div id="about-pic">
                <img id="ab-pic" src="../assets/images/accentS.jpg" alt="about-pic">
            </div>

            <div id="about-info">
                <h1 style="text-align: center;">About Us</h1>
                <p>Stilson’s have been living and working in the construction industry in the Saint George area for 37 years. Blaine and Jason Stilson have always been passionate about the field. They have always loved holiday lighting and have installed multiple brands of lighting for the past 15 years. 4 years ago, they settled on Jellyfish Lighting because of their superior features, quality, and ease of use.</p>
                <button id="learn-more-button" @click="redirect('about')">Learn More</button>
            </div>

        </div>
    </div>

    <div id="gall-abt">
        <h1 id="gall-header">Gallery</h1>

        <div id="gall-grad">



          <v-carousel hide-delimiters height="600px">
            <v-carousel-item height="600px"
              src="../assets/images/rainbow.jpeg"
            ></v-carousel-item>
            <v-carousel-item height="600px"
              src="../assets/images/white.jpeg"
            ></v-carousel-item>
            <v-carousel-item height="600px"
              src="../assets/images/thanksgiving.jpeg"
            ></v-carousel-item>
          </v-carousel>


        </div>

        <div id="about-part">
            <div id="about-pic">
                <img id="ab-pic" src="../assets/images/accentS.jpg" alt="about-pic">
            </div>

            <div id="about-info">
                <h1 id="about-header">About Us</h1>
                <p>Stilson’s have been living and working in the construction industry in the Saint George area for 37 years. Blaine and Jason Stilson have always been passionate about the field. They have always loved holiday lighting and have installed multiple brands of lighting for the past 15 years. 4 years ago, they settled on Jellyfish Lighting because of their superior features, quality, and ease of use.</p>
                <button id="learn-more-button" @click="redirect('about')">Learn More</button>
            </div>

        </div>

        <div id="reviews-section">
            <div id="rev-head-flex">
                <h4 id="rev-head">COMMENTS</h4>
                <button id="rev-head-but">Clients Say</button>
            </div>

            <div class="review-flex">
                <div class="review">
                    <div>
                        <h6 class="review-h6">Stephanie</h6>
                        <div class="stars">
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                        </div>
                    </div>
                    <p class="review-p">We hired Jason Stilson a year ago to install our Jellyfish lighting system. They were timely and efficient in installation and available to discuss patterns for various holidays. Our neighbors are delighted that we have year-round celebrations. In fact, others on the street have also installed Jellyfish. We are so pleased that we can celebrate each holiday throughout the year with just the flip of a switch!!</p>
                </div>
                <div class="review">
                    <div>
                        <h6 class="review-h6">The Thomas's</h6>
                        <div class="stars">
                          <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                        </div>
                    </div>
                    <p class="review-p">We LOVE our JellyFish Lighting. It's so fun to be able to change the colors whenever we want, especially for holidays, parties & football season! Stilson's Central Lighting was great to work with and did a wonderful job installing our lights, training us on the system and answering our questions. I highly recommend working with them to install your JellyFish Lighting!</p>
                </div>
                <div class="review">
                    <div>
                        <h6 class="review-h6">Wade and Misty</h6>
                        <div class="stars">
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                        </div>
                    </div>
                    <p class="review-p">Jason and his crew did an amazing job installing our jellyfish lights. These guys are very meticulous and don’t cut corners with anything they do. We absolutely love the lights and the ease of turning them on and changing colors for all different occasions. We are so glad we chose this company to work with. Best in the business hands-down.</p>
                </div>
                <!-- <div class="review">
                    <div>
                        <h6 class="review-h6">Author</h6>
                        <div class="stars">
                          <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                        </div>
                    </div>
                    <p class="review-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div> -->
                <!-- <div class="review">
                    <div>
                        <h6 class="review-h6">Author</h6>
                        <div class="stars">
                          <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                            <v-icon style="color:white">mdi-star</v-icon>
                        </div>
                    </div>
                    <p class="review-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                </div> -->
                

            </div>

        </div>

        <div id="footer">
          <div>
            <h1 id="reqHead">Request A Free Quote Today!</h1>
          </div>
            <div id="footer-quote">
                <input type="text" v-model="name" value="" placeholder="Name" class="quote-item">
                <input type="text" v-model="email" value="" placeholder="Email" class="quote-item">
                <input type="text" v-model="phone" value="" placeholder="Phone" class="quote-item">
                <button class="quote-button" @click="sendMail()">Request Quote</button>
            </div>

            <div id="footer-content">
                <img src="../assets/images/stilsons-lighting2.svg" alt="" class="footer-logo">
                <h6 class="footer-text">St. George Area: 1205 East 1050 South Circle, 84790</h6>
                <h6 class="footer-text"><a href="https://www.stilsons.com/">Our Partner Company: Stilson’s Central Vacuum</a></h6>
                <!-- <div class="icon-cont"> <img src="../assets/images/facebook.png" alt="" class="social-icons"> <img src="images/instagram.png" alt="" class="social-icons"></div> -->
            </div>

        </div>

    </div>
 </div>


</template>

<script>
  

  export default {
    name: 'Home',
    data(){
      return {
        desktop: true,
        drawer: false,
        group: null,
        residential: false,
        resources: false,
        items: [
          {
            src: '/src/assets/images/rainbow.jpeg'
          },
          {
            src: '/src/assets/images/thanksgiving.jpeg'
          },
          {
            src: '/src/assets/images/white.jpeg'
          }
        ],
        name: "",
        email: "",
        phone: "",
      }
    },
    created(){
      window.scrollTo(0, 0);
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width1 <= 768){
          this.desktop = false;
        }
    },
    methods: {
      redirect(x){
        this.$router.push(`/${x}`);
      },
      sendMail() {
        //console.log("hello1");
        window.open(`mailto:stilsons@stilsons.com?subject=${this.name} requested a quote&body=${this.name} requested a quote. %0D%0A Here is their email: ${this.email} %0D%0A Phone number: ${this.phone}. %0D%0A %0D%0A Any additional comments:  %0D%0A %0D%0A Sent from Stilson's Lighting Website`);
        // const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // myHeaders.set('Authorization', 'Basic ' + btoa('4ed175f8dd7b65e7bc5c761f95e5a28b'+":" +'6f60aa09263b08f8703e8ccc635dbd43'));
        // console.log("hello2");

        // const data = JSON.stringify({
        //   "Messages": [{
        //     "From": {"Email": this.email, "Name": this.name},
        //     "To": [{"Email": "stilsons@stilsons.com", "Name": "Stilson's Lighting"}],
        //     "Subject": `${this.name} requested a quote`,
        //     "TextPart": `${this.name} requested a quote here is their email: ${this.email} and phone number: ${this.phone}`,
        //   }]
        // });
        // console.log("data: ", data);

        // const requestOptions = {
        //   method: 'POST',
        //   mode: 'no-cors',
        //   headers: myHeaders,
        //   body: data,
        // };

        // fetch("https://api.mailjet.com/v3.1/send", requestOptions)
        //   .then(response => response.text(
        //     console.log("response: ", response)
        //   ))
        //   .then(result => console.log(result))
        //   .catch(error => console.log('error', error));
      },
      

        

    },
    computed: {
      size(){
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width <= 768){
          this.desktop = false;
        }
      }
      
    }

    // components: {
    //   HelloWorld,
    // },
  }
</script>

<style scoped>
@import "../assets/accord.css";
@import "../assets/style.css";



.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-top: 10px;
}

.dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding-left: 42px;
    font-size: 18px;
}
.dropdown-content a {
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    margin-left: 22px;
    font-size: 18px;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    width: 200px;
}

.dropdown-content a:hover {
    background-color: #f1f1f157;
}

#reqHead{
  width: 100%;
  text-align: center;
  padding-top: 50px;
}

#learn-more-button{
  font-family:'Roboto', Geneva, Tahoma, sans-serif;
}

#ham-icon{
  color: white;
  width: 25px;
  height: 25px;
}
.subNav{
  padding-left: 20px;
}
.v-navigation-drawer__content{
  background-color: #4D4B68;
}
.v-list-item__title{
  color: white;
  font-family: 'Roboto';
}
h3{
  margin: 50px 0px;
}
#about-header{
  text-align: left;
}
#rev-head{
  margin-top: 50px;
}
#rev-head-but{
  font-family: 'Roboto';
}
.review-p{
  width: 95%;
  padding-bottom: 30px;
}
#normal-logo{
  margin-top: unset;
}
.accord-list > div > button {
  color: white;
  font-family: 'Roboto';
  font-size: 24px;
  margin-top: 20px;
}
.accord-list2 > div > button {
  color: white;
  font-family: 'Roboto';
  font-size: 24px;
  margin-top: 20px;
}

.v-window__next {
    right: 0;
}
.v-navigation-drawer--close{
  display: none;
}
@media (max-width: 1024px){
  #reqHead{
    font-size: 60px;
  }
  .dropbtn{
    font-size: 12px;
  }
  .dropdown-content a{
    font-size: 12px;
  }
}

@media (max-width: 768px){
  #reqHead{
    font-size: 50px;
  }
  .quote-item{
    width: 80%;
  }
  .quote-button{
    width: 80%;
  }
  .review > div{
    flex-direction: column;
    align-items: flex-start;
  }
  .review{
    width: 75vw;
  }
  .review-flex{
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px){
  #footer-quote{
    padding: 50px 50px 0px 50px;
  }
  #about-part{
    display: none;
  }
  .quote-item{
    width: 100%;
  }
  .quote-button{
    width: 100%;
  }
  .review > p{
    line-height: 18px !important;
    font-size: 12px !important;
    width: 58vw;
    padding-left: 21px;
    margin-top: 18px;
    padding-top: 15px;
  }
  .review-h6{
    padding-bottom: 6px;
  }
  .v-list-item__title{
    font-size: 1rem !important;
  }
  p{
    margin-top: 0px !important;
    width: 100% !important;

  }
  #rain1m{
    margin: 50px 25px;
  }
  #rain2m{
    margin: 50px 25px;
  }
  #rain3m{
    margin: 50px 25px;
  }
  #rain4m{
    margin: 50px 25px;
  }
  #rain5m{
    margin: 50px 25px;
  }
  #rain6m{
    margin: 50px 25px;
  }
  #rain7m{
    margin: 50px 25px;
  }
  #rain8m{
    margin: 50px 25px;
  }
}
@media (max-width: 320px){
  #reqHead{
    font-size: 36px;
  }
  .applict > h3 {
    top: 480px;
  }
}

</style>


