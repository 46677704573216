import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=67ec0922&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=js&"
export * from "./Gallery.vue?vue&type=script&lang=js&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=67ec0922&prod&scoped=true&lang=css&"
import style1 from "./Gallery.vue?vue&type=style&index=1&id=67ec0922&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ec0922",
  null
  
)

/* custom blocks */
import block0 from "./Gallery.vue?vue&type=custom&index=0&blockType=head"
if (typeof block0 === 'function') block0(component)

export default component.exports