<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
  </head>
  
  <template>
    <div id="accent">
      <div id="main-grid">
        <div id="header-nav">
          <div id="normal-logo" @click="redirect('')"><img id="logo" src="../assets/images/stilsons-lighting2.svg" alt="logo"></div>
          <div id="menu" v-if="desktop == true">
              <a href="" @click="redirect('')">Home</a>
              <a href="" @click="redirect('about')">Our Company</a>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Residential Lighting</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('accent')">Accent Lighting</a>
                      <a href="" @click="redirect('holiday')">Holiday Lighting</a>
                      <a href="" @click="redirect('security')">Security Lighting</a>
                      <a href="" @click="redirect('gameday')">Game Day Lighting</a>
                  </div>
              </li>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Resources</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('consult')">Contact Us</a>
                      <a href="" @click="redirect('faq')">FAQ</a>
                  </div>
              </li>
              <button id="nav-button" @click="redirect('consult')">Request Quote</button>
            </div>
          <div v-if="desktop == false">
            <v-icon id="ham-icon"  @click="drawer = true" size="30px">mdi-menu</v-icon>
  
            <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              right
            >
              <v-list
                nav
                dense
              >
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item>
                    <v-list-item-title @click="redirect('')">Home</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title @click="redirect('about')">Our Company</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item @click="residential = !residential">
                    <v-list-item-title>Residential Lighting</v-list-item-title>
                  </v-list-item>
  
                  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('accent')">Accent Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('holiday')">Holiday Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('security')">Security Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('gameday')">Game Day Lighting</v-list-item-title>
                  </v-list-item>
  
                  <!-- <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('')">Commercial</v-list-item-title>
                  </v-list-item> -->
                  
  
  
                  <v-list-item @click="resources = !resources">
                    <v-list-item-title>Resources</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav" @click="redirect('faq')">FAQ Page</v-list-item-title>
                  </v-list-item>
                  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav" @click="redirect('consult')">Contact Us</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title @click="redirect('consult')">Free Consultation</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
              
          </div>
        </div>
      </div>
  
  
      <div id="body-grid">
          <div class="color-head">
            <h1 id="hero">Holiday Lighting</h1>
          </div>
          <div id="header-img-flex"><img src="../assets/holidayPics/valentines-day-outdoor-lighting-scaled.jpg" alt="" class="header-img"></div>
          <div id="back-ground">
            <h2 id="top-subs">Perminent Holiday Lighting, Perfect For Any Occasion All Year Round</h2>
            <div id="set-flex">
                <img src="../assets/holidayPics/4th-of-july-jellyfish-lighting-scaled.jpg" id="accent-pic" alt="">
                <div id="bullet-flex">
                    <div>
                        <img src="../assets/images/bulletPoint.svg" alt="">
                        <h6>Create you own custom lighting patterns with over 16 million different colors to choose form.</h6>
                    </div>
                    <div>
                        <img src="../assets/images/bulletPoint.svg" alt="">
                        <h6>Comes with over 102 presets that are popular during every holiday.</h6>
                    </div>
                    <div>
                        <img src="../assets/images/bulletPoint.svg" alt="">
                        <h6>Save events on your calander, and schedule your lights to turn on automatically for any special occasion.</h6>
                    </div>
                    <div>
                        <img src="../assets/images/bulletPoint.svg" alt="">
                        <h6>With the calander feature you can schedule holidays with your accent lighting. This way you can easily go from holiday lighting to accent lighting automatically.</h6>
                    </div>
                    
                </div>
            </div>
  
          </div>
  
      </div>

      <div class="color-head" id="slider" style="height: 500px; align-items: center !important;">
        <v-carousel hide-delimiters height="400px">
            <v-carousel-item height="400px" width="800px"
                src="../assets/holidayPics/4th-of-july-jellyfish-lighting-scaled.jpg"
            ></v-carousel-item>
            <v-carousel-item height="400px"
                src="../assets/holidayPics/halloween-outdoor-lights.jpg"
            ></v-carousel-item>
            <v-carousel-item height="400px"
                src="../assets/holidayPics/permanent-color-changing-christmas-lights-scaled.jpg"
            ></v-carousel-item>
            <v-carousel-item height="400px"
                src="../assets/holidayPics/valentines-day-outdoor-lighting-scaled.jpg"
            ></v-carousel-item>
        </v-carousel>
      </div>
  
      <div id="footer">
        <div>
          <h1 id="reqHead">Request A Free Quote Today!</h1>
        </div>
        <div id="footer-quote">
            <input type="text" v-model="name" value="" placeholder="Name" class="quote-item">
            <input type="text" v-model="email" value="" placeholder="Email" class="quote-item">
            <input type="text" v-model="phone" value="" placeholder="Phone" class="quote-item">
            <button class="quote-button" @click="sendMail()">Request Quote</button>
        </div>
  
        <div id="footer-content">
            <img src="../assets/images/stilsons-lighting2.svg" alt="" class="footer-logo">
            <h6 class="footer-text">St. George Area: 1205 East 1050 South Circle, 84790</h6>
            <h6 class="footer-text"><a href="https://www.stilsons.com/">Our Partner Company: Stilson’s Central Vacuum</a></h6>
        </div>
  
      </div>
  
  
  
  
  
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'About',
    data(){
      return {
        desktop: true,
        drawer: false,
        group: null,
        residential: false,
        resources: false,
        name: "",
        email: "",
        phone: ""
        
      }
    },
    created(){
      window.scrollTo(0, 0);
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width1 <= 768){
          this.desktop = false;
        }
    },
    methods: {
      redirect(x){
        console.log("x: ", x);
        this.$router.push(`/${x}`);
      },
      sendMail(){
        window.open(`mailto:stilsons@stilsons.com?subject=${this.name} requested a quote&body=${this.name} requested a quote. %0D%0A Here is their email: ${this.email} %0D%0A Phone number: ${this.phone}. %0D%0A %0D%0A Any additional comments:  %0D%0A %0D%0A Sent from Stilson's Lighting Website`);
      }
    },
    computed: {
      size(){
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width <= 768){
          this.desktop = false;
        }
      }
      
      
    }
  }
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #37445B;
    margin-left: 20px;
}

.dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding-left: 42px;
    font-size: 18px;
}
.dropdown-content a {
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    /* margin-left: 22px; */
    font-size: 18px;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    width: 200px;
}

.dropdown-content a:hover {
    background-color: #f1f1f157;
}
  #reqHead{
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
  .footer-text{
    font-size: 12px;
    padding: 0px;
  }
  #normal-logo{
    margin-top: 0px;
  }
  #main-grid{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  #body-grid{
    width: 100%;
    margin: 0px;
    display: grid;
    grid-template-columns: minmax(0px,100px) repeat(6, minmax(0,575px)) minmax(0px,100px);
  }
  .color-head{
    grid-column: -1/1;
    grid-row: 1/3;
    height: 400px;
    background: linear-gradient(180deg, #67B3E4 0%, #C292DE 100%);
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
  }
  #header-img-flex{
    grid-column: 1/-1;
    grid-row: 2/4;
    height: auto;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .header-img{
    width: 32%;
  }
  #hero{
    width: unset; 
    text-align:center;
    padding-top: 32px;
  }
  #back-ground{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: -1/1;
    grid-row: 3/4;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  #top-subs{
    margin-top: 15%;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    width: 75%;
    line-height: unset;
  }
  p{
    width: 75%;
    margin-top: 100px;
  }
  #subs{
    font-size: 72px;
    font-weight: bold;
  }
  #set-flex{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 100px;
  }
  #bullet-flex{
    padding-left: 100px;
    color: white;
    font-family: 'Roboto';
    line-height: 35px;
  }
  h6{
    font-size: 18px;
    padding-left: 20px;
  }
  #bullet-flex > div{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  #slider{
    display: flex;
    height: 500px;
    justify-content: center;
    align-items: center;
  }
  .v-item-group{
    width: 800px;
  }
  #accent-pic{
    height: 400px;
  }
  .v-navigation-drawer--close{
  display: none;
}
@media (max-width: 1440px){
  .header-img{
    width: 50%;
  }
  #top-subs{
    margin-top: 23%;
  }
}
@media (max-width:1024px){
  #top-subs{
    margin-top: 200px !important;
  }
  #accent-pic{
    height: 200px;
  }
  #reqHead{
    font-size: 60px;
  }
  .header-img{
    width: 56%;
  }
  .dropbtn{
    font-size: 12px;
  }
  .dropdown-content a{
    font-size: 12px;
  }
}
@media (max-width: 768px){
  #reqHead{
    font-size: 50px;
  }
  .quote-item{
    width: 80%;
  }
  .quote-button{
    width: 80%;
  }
  .color-head{
    height: 348px !important;
  }
  #hero{
    padding-top: 5% !important;
  }
  #top-subs{
    font-size: 36px !important;
  }
  #set-flex{
    flex-direction: column;
  }
  #accent-pic{
    height: 300px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 100px;
  }
  #bullet-flex{
    padding-left: 0px;
  }
  .quote-item{
    width: 100%;
  }
  .quote-button{
    width: 100%;
  }

}
@media (max-width: 425px){
  #footer-quote{
    padding: 50px 50px 0px 50px;
  }
  #reqHead{
    margin-top: 100px;
  }
  .color-head{
    height: 183px !important;
  }
  #top-subs{
    margin-top: 140px !important;
    font-size: 24px !important;
  }
  #set-flex{
    padding: 25px;
    margin-bottom: 100px;
  }
  #accent-pic{
    height: 200px;
    margin-bottom: 25px;
  }
  #footer-quote{
    margin-top: 100px;
    padding: 50px !important;
  }
  .header-img{
    width: 84%;
  }
}
@media (max-width: 375px){
  #top-subs{
    margin-top: 108px !important;
    font-size: 20px !important;
  }
  
}
@media (max-width: 320px){
  #top-subs{
    margin-top: 85px !important;
    font-size: 18px !important;
  }
  #accent-pic{
    height: 150px;
  }
  h6{
    font-size: 14px;
    line-height: 28px;
  }
  #reqHead{
    font-size: 36px;
  }
}
  </style>