<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
  
<script>

export default {
  name: 'App',

  metaInfo: {
      titleTemplate: "%s | Jellyfish Lighting",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Get all your lighting in one place",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    },
};
</script>

<style>
.v-application--wrap {
  min-height: 0px !important;
}
#ham-icon{
  color: white;
  width: 25px;
  height: 25px;
}
.subNav{
  padding-left: 20px;
}
.v-navigation-drawer__content{
  background-color: #4D4B68;
}
.v-list-item__title{
  color: white;
  font-family: 'Roboto';
}
h3{
  margin: 50px 0px;
}
#about-header{
  text-align: left;
}
#rev-head{
  margin-top: 50px;
}
#rev-head-but{
  font-family: 'Roboto';
}
.review-p{
  width: 95%;
  padding-bottom: 30px;
}
#normal-logo{
  margin-top: unset;
}
button{
  font-family: 'Roboto';
}
#footer-quote{
  margin: 0px !important;
  padding: 50px 100px;
}
#footer-content{
  margin-bottom: 0px !important;
  padding-bottom: 50px;
}
</style>
