<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
  </head>
  
  <template>
    <div id="accent">
      <div id="main-grid">
        <div id="header-nav">
          <div id="normal-logo" @click="redirect('')"><img id="logo" src="../assets/images/stilsons-lighting2.svg" alt="logo"></div>
          <div id="menu" v-if="desktop == true">
              <a href="" @click="redirect('')">Home</a>
              <a href="" @click="redirect('about')">Our Company</a>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Residential Lighting</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('accent')">Accent Lighting</a>
                      <a href="" @click="redirect('holiday')">Holiday Lighting</a>
                      <a href="" @click="redirect('security')">Security Lighting</a>
                      <a href="" @click="redirect('gameday')">Game Day Lighting</a>
                  </div>
              </li>
              <li class="dropdown">
                  <a href="#" class="dropbtn">Resources</a>
                  <div class="dropdown-content">
                      <a href="" @click="redirect('consult')">Contact Us</a>
                      <a href="" @click="redirect('faq')">FAQ</a>
                  </div>
              </li>
              <button id="nav-button" @click="redirect('consult')">Request Quote</button>
            </div>
          <div v-if="desktop == false">
            <v-icon id="ham-icon"  @click="drawer = true" size="30px">mdi-menu</v-icon>
  
            <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              right
            >
              <v-list
                nav
                dense
              >
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item>
                    <v-list-item-title @click="redirect('')">Home</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title @click="redirect('about')">Our Company</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item @click="residential = !residential">
                    <v-list-item-title>Residential Lighting</v-list-item-title>
                  </v-list-item>
  
                  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('accent')">Accent Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('holiday')">Holiday Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('security')">Security Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav" @click="redirect('gameday')">Game Day Lighting</v-list-item-title>
                  </v-list-item>
  
                  <!-- <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Commercial</v-list-item-title>
                  </v-list-item> -->
                  
  
  
                  <v-list-item @click="resources = !resources">
                    <v-list-item-title>Resources</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav" @click="redirect('faq')">FAQ Page</v-list-item-title>
                  </v-list-item>
                  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav" @click="redirect('consult')">Contact Us</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title @click="redirect('consult')">Free Consultation</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
              
          </div>
        </div>
      </div>
  
  
      <div id="body-grid">
          <div class="color-head">
            <h1 id="hero">FAQ</h1>
          </div>
      </div>



      
  
      <div id="footer">
        <v-expansion-panels dark flat>
            <v-expansion-panel>
                <v-expansion-panel-header size="38px">
                    What is JellyFish Lighting?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    JellyFish Lighting is a permanent color-changing LED lighting system for the exterior of homes. It is capable of everything from nightly accent lighting that mimics recessed can lighting to any color and pattern for your holidays and parties. Easily decorate for all occasions like Christmas, Valentine’s Day, Easter, Memorial Day, Fourth of July, Halloween, Birthday Parties, and more!
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Can I make my own patterns?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Yes you can! The lights are individually addressable which allows you to choose which lights you want on and what color (over 16 million different colors). We also have 102 preset patterns.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Can I make my own accent lighting?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Yes you can! The lights are individually addressable which allows you to choose which lights you want on and what color (anything from bright to soft white). For example, you can turn on two lights at the top of each peak while the rest of the lights stay off.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Can I save timers?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Yes, you can save multiple nightly & weekly timers as well as calendar events for holidays and other special occasions. We are linked with weather.com so you can link with sunset to sunrise.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Are the lights dimable?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Yes they are!
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    How long does the system last?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    JellyFish Lighting System is a robust, outdoor rated LED lighting system. LED lighting is known for its durability. The LEDs are rated for years and years of use.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    What is the warranty?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    We have a warranty of 1 year covering labor and 3 years covering parts.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Can I make a soft white color?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Absolutely! You can make any color of white.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    How many different colors and patterns can you do?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    The lights are RGB and can produce over 16 million different colors. The user can create custom programs and colors, so the possibilities are limitless.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Can I control the front of my home separately from the back?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Yes, we have zoning which allows the ability to only turn on certain sections of lights or to have different patterns on different parts of your home at the same time.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Do I have to upgrade my hardware to get new features?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    With the P2 system, controller updates are sent to your email with instructions on how to update your firmware. App updates will happen through your device’s app store.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Where are the controller and power supplies installed?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    The controller and any additional power supplies are installed indoors, typically on a wall in your garage safe from the elements. They plug into an outlet. One controller can power up to 300 ft depending on the wiring. From there, we run low voltage wire from your garage to the soffit of the first section of roofing.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    What maintenance is required?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    From time to time a light will go out and would need to be replaced.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Where are the lights installed?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    The lights are installed to the outer part of the soffit behind the fascia or trim facing downward.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    How do payments work?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Half down is due to schedule an installation date. The other half is due upon completion.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Where is JellyFish Lighting made?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Our controller is manufactured in our factory in Orem, Utah <br>
                    Our metal is fabricated locally in Utah, Colorado, and Texas <br>
                    We employ local USA citizens in all locations <br>
                    In order to make our product more accessible, we manufacture our lights in Asia
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    How long has JellyFish Lighting been in business?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    The inventor of JellyFish Lighting has been working with his color-changing exterior lights since 2011, and under the JellyFish Lighting name since 2016.
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    How much electricity does the system use?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    The lights on full bright white use less than one watt of power. Also, the controller pulls about 1.1 amps of electricity per 100 feet.
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>









        <div id="footer-quote">
            <input type="text" v-model="name" value="" placeholder="Name" class="quote-item">
            <input type="text" v-model="email" value="" placeholder="Email" class="quote-item">
            <input type="text" v-model="phone" value="" placeholder="Phone" class="quote-item">
            <button class="quote-button" @click="sendMail()">Request Quote</button>
        </div>
  
        <div id="footer-content">
            <img src="../assets/images/stilsons-lighting2.svg" alt="" class="footer-logo">
            <h6 class="footer-text">St. George Area: 1205 East 1050 South Circle, 84790</h6>
            <h6 class="footer-text"><a href="https://www.stilsons.com/">Our Partner Company: Stilson’s Central Vacuum</a></h6>
        </div>
  
      </div>
  
  
  
  
  
    </div>
  
  </template>
  
  <script>
  export default {
    name: 'About',
    data(){
      return {
        desktop: true,
        drawer: false,
        group: null,
        residential: false,
        resources: false,
        name: "",
        email: "",
        phone: ""

        
      }
    },
    created(){
        window.scrollTo(0, 0);
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width1 <= 768){
          this.desktop = false;
        }
    },
    methods: {
      redirect(x){
        console.log("x: ", x);
        this.$router.push(`/${x}`);
      },
      sendMail(){
        window.open(`mailto:stilsons@stilsons.com?subject=${this.name} requested a quote&body=${this.name} requested a quote. %0D%0A Here is their email: ${this.email} %0D%0A Phone number: ${this.phone}. %0D%0A %0D%0A Any additional comments:  %0D%0A %0D%0A Sent from Stilson's Lighting Website`);
      }
    },
    computed: {
      size(){
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width <= 768){
          this.desktop = false;
        }
      }
      
    }
  }
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #37445B;
    margin-left: 20px;
}

.dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding-left: 42px;
    font-size: 18px;
}
.dropdown-content a {
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    /* margin-left: 22px; */
    font-size: 18px;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    width: 200px;
}

.dropdown-content a:hover {
    background-color: #f1f1f157;
}
  .v-expansion-panel-content__wrap{
    font-size: 24px;
    padding: 0 40px 16px;
  }
  .v-expansion-panel-header__icon{
    font-size: 38px !important;
    padding-left: 20px;
  }
  .v-icon{
    font-size: 38px !important;
  }
  .v-expansion-panel-header{
    /* border-bottom: 1px solid white; */
    font-size: 38px;
    padding: 20px 40px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active{
    border-bottom: 1px solid white;
  }
  .v-expansion-panel{
    background-color: unset !important;
    color: white !important;
    font-family: 'Roboto';
    font-size: 24px;
    padding: 0 50px 20px !important;
    
  }
  .theme--light.v-icon{
    color: white !important;
  }
  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
    color: white !important;
  }
  .theme--dark.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
    font-size: 38px;
  }
  #normal-logo{
    margin-top: 0px;
  }
  #main-grid{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  #body-grid{
    width: 100%;
    margin: 0px;
    display: grid;
    grid-template-columns: minmax(0px,100px) repeat(6, minmax(0,575px)) minmax(0px,100px);
  }
  .color-head{
    grid-column: -1/1;
    grid-row: 1/3;
    height: 400px;
    background: linear-gradient(180deg, #67B3E4 0%, #C292DE 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #header-img{
    grid-column: 3/7;
    grid-row: 2/4;
    height: auto;
    width: 100%;
    z-index: 1;
  }
  #hero{
    width: unset; 
    text-align:center;
    padding-top: 0px;
  }
  #back-ground{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: -1/1;
    grid-row: 3/4;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  #top-subs{
    margin-top: 400px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    width: 75%;
    line-height: unset;
  }
  p{
    width: 75%;
    margin-top: 100px;
  }
  #subs{
    font-size: 72px;
    font-weight: bold;
  }
  #footer-quote{
    align-items: center;
    flex-direction: row;
  }
  .quote-item{
    width: 100%;
  }
  .quote-button{
    width: 100%;
  }
  .v-navigation-drawer--close{
  display: none;
  }
  @media(max-width: 1024px){
    .v-expansion-panel-header{
        font-size: 30px;
    }
    .dropbtn{
    font-size: 12px;
  }
  .dropdown-content a{
    font-size: 12px;
  }

  }
  @media(max-width: 768px){
    .v-expansion-panel-header{
        font-size: 18px;
        text-align: left;
    }
    .v-expansion-panel{
        padding: 0px !important;
        font-size: 18px;
    }
  }
  @media(max-width: 425px){

    #footer-quote{
      margin-top: 0px !important;
    }
    .color-head{
      height: 200px;
    }
    #footer-quote{
      padding: 50px;
    }
    .v-expansion-panel{
        padding: 0px;
    }
    .v-expansion-panel-content__wrap{
        padding: 0 30px 16px;
  }
  }
  @media(max-width: 320px){

    #footer-quote{
      padding: 25px !important;
    }
    .v-expansion-panel-content__wrap{
        padding: 0px 40px 16px;
  }
  }
  </style>