import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import AccentLighting from '@/views/AccentLighting.vue';
import FreeCon from '@/views/FreeCon.vue';
import Gallery from '@/views/Gallery.vue';
import Holiday from '@/views/Holiday.vue';
import GameDay from '@/views/GameDay.vue';
import Security from '@/views/Security.vue';
import FAQ from '@/views/FAQ.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'our company',
    component: AboutView
  },
  {
    path: '/accent',
    name: 'accent lighting',
    component: AccentLighting
  },
  {
    path: '/consult',
    name: 'free consult',
    component: FreeCon
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/holiday',
    name: 'holiday',
    component: Holiday
  },
  {
    path: '/gameday',
    name: 'gameday',
    component: GameDay
  },
  {
    path: '/security',
    name: 'security',
    component: Security
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
