<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
  </head>
  
  <template>
    <div id="accent">
      <div id="main-grid">
        <div id="header-nav">
          <div id="normal-logo" @click="redirect('')"><img id="logo" src="../assets/images/stilsons-lighting2.svg" alt="logo"></div>
          <div id="menu" v-if="desktop == true">
              <a href="">Home</a>
              <a href="">Our Company</a>
              <a href="">Residential Lighting</a>
              <a href="">Resources</a>
              <button id="nav-button">Request Quote</button>
          </div>
          <div v-if="desktop == false">
            <v-icon id="ham-icon"  @click="drawer = true" size="30px">mdi-menu</v-icon>
  
            <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              right
            >
              <v-list
                nav
                dense
              >
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item>
                    <v-list-item-title>Home</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title>Our Company</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item @click="residential = !residential">
                    <v-list-item-title>Residential Lighting</v-list-item-title>
                  </v-list-item>
  
                  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Accent Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Holiday Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Security Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Game Day Lighting</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="residential == true">
                    <v-list-item-title class="subNav">Commercial</v-list-item-title>
                  </v-list-item>
                  
  
  
                  <v-list-item @click="resources = !resources">
                    <v-list-item-title>Resources</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav">FAQ Page</v-list-item-title>
                  </v-list-item>
                  
                  <v-list-item v-if="resources == true">
                    <v-list-item-title class="subNav">Contact Us</v-list-item-title>
                  </v-list-item>
  
                  <v-list-item>
                    <v-list-item-title>Free Consultation</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
              
          </div>
        </div>
      </div>
  
  
      <div id="body-grid">
          <div class="color-head">
            <h1 id="hero">Gallery</h1>
          </div>
      </div>

      <div id="back-ground">
   
      </div>

      
  
      <div id="footer">
        <div id="footer-quote">
            <input type="text" value="" placeholder="Name" class="quote-item">
            <input type="text" value="" placeholder="Email" class="quote-item">
            <input type="text" value="" placeholder="Phone" class="quote-item">
            <button class="quote-button">Request Quote</button>
        </div>
  
        <div id="footer-content">
            <img src="../assets/images/stilsons-lighting2.svg" alt="" class="footer-logo">
            <h6 class="footer-text">St. George Area: 1205 East 1050 South Circle, 84790</h6>
            <h6 class="footer-text"><a href="https://www.stilsons.com/">Our Partner Company: Stilson’s Central Vacuum</a></h6>
        </div>
  
      </div>
  
  
  
  
  
    </div>
  
  </template>
  
  <script>
  import VueGallery from 'vue-gallery';
  export default {
    name: 'About',
    data(){
      return {
        desktop: true,
        drawer: false,
        group: null,
        residential: false,
        resources: false,
        // images: [{
        //     id: 1,
        //     imgSrc: require("../assets/images/white.jpeg")
        // },
        // {
        //     id: 2,
        //     imgSrc: require("../assets/images/rainbow.jpeg")
        // },
        // {
        //     id: 3,
        //     imgSrc: require("../assets/images/4th.jpeg")
        // },
        // {
        //     id: 4,
        //     imgSrc: require("../assets/images/thanksgiving.jpeg")
        // },
        // {
        //     id: 5,
        //     imgSrc: require("../assets/images/blue.jpg")
        // },
        // {
        //     id: 6,
        //     imgSrc: require("../assets/images/accent.jpg")
        // }]

        images: [
            "../assets/images/white.jpeg",
            "../assets/images/rainbow.jpeg"
        ],
        index: null
        
      };
    },
    components: {
        'gallery': VueGallery
    },
    created(){
      window.scrollTo(0, 0);
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width1 <= 768){
          this.desktop = false;
        }
    },
    methods: {
  
    },
    computed: {
      size(){
        const width1 = window.innerWidth;
        console.log("width: ", width1);
        if(width <= 768){
          this.desktop = false;
        }
      }
      
    }
  }
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
    padding-top: 10px;
}

.dropbtn {
    border: none;
    outline: none;
    background-color: inherit;
    color: white;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    font-weight: 400;
    text-decoration: none;
    padding-left: 42px;
    font-size: 18px;
}
.dropdown-content a {
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    margin-left: 22px;
    font-size: 18px;
    font-family: 'Roboto', Geneva, Tahoma, sans-serif;
    width: 200px;
}

.dropdown-content a:hover {
    background-color: #f1f1f157;
}
  #main-grid{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  #body-grid{
    width: 100%;
    margin: 0px;
    display: grid;
    grid-template-columns: minmax(0px,100px) repeat(6, minmax(0,575px)) minmax(0px,100px);
  }
  .color-head{
    grid-column: -1/1;
    grid-row: 1/3;
    height: 400px;
    background: linear-gradient(180deg, #67B3E4 0%, #C292DE 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #header-img{
    grid-column: 3/7;
    grid-row: 2/4;
    width: 100%;
    z-index: 1;
  }
  #hero{
    width: unset; 
    text-align:center;
    padding-top: 0px;
  }
  #back-ground{
    background-image: url(../assets/images/grad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: -1/1;
    grid-row: 3/4;
    
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  #top-subs{
    margin-top: 400px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    width: 75%;
    line-height: unset;
  }
  p{
    width: 75%;
    margin-top: 100px;
  }
  #subs{
    font-size: 72px;
    font-weight: bold;
  }
  #footer-quote{
    align-items: center;
    flex-direction: row;
  }
  .quote-item{
    width: 100%;
  }
  .quote-button{
    width: 100%;
  }
  </style>
  <style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
  .v-navigation-drawer--close{
  display: none;
}
</style> 